import {useState} from 'react'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/navigation'
import clsx from 'clsx'
import {FaLinkedin} from 'react-icons/fa'
import {FaXTwitter} from 'react-icons/fa6'

import {
  softwareIcons,
  // footerHeaderData,
  footerProductsData,
  footerSolutionsData,
  footerCompanyData,
  footerAddressData,
  bottomFooterData,
} from 'v1/content/footer'
import Button from '../button/Button'
import ListSection from './ListSection'
import IconsMarquee from 'v1/common-components/icons-marquee/IconsMarquee'
import Review from 'v1/common-components/review/Review'
import Logo from 'v1/assets/icons/logo-zenadmin-white.svg'
import BgImage from 'v1/assets/icons/footer/footer-bg-image.webp'
import ISOFooter from 'assets/icons/footer/ISO-footer.svg'
import FooterImage from 'v1/assets/icons/footer/footer-image.webp'
import ArrowRightIcon from 'v1/assets/icons/arrow-right-white.svg'
import classes from './styles.module.css'
import {DEMO_LINK} from 'v1/utils/getters'
import WhyZenAdmin from '../why-zenadmin/WhyZenAdmin'
import {IFooter} from '../page-layout/PageLayout'

export default function Footer({
  showWhyZenadmin = true,
  showReviewSection = true,
  blueCardData = {},
}: IFooter) {
  const router = useRouter()
  const [text, setText] = useState('')
  const [error, setError] = useState<string | boolean>(false)
  const repeatedSoftwareIcons = [
    ...softwareIcons,
    ...softwareIcons,
    ...softwareIcons,
    ...softwareIcons,
  ]

  const handleOnClick = (link: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRegex.test(text)) {
      router.push(link)
    } else {
      setError('Wrong Email')
    }
  }

  return (
    <section className={classes.mainFooterContainer}>
      {showReviewSection && <Review />}
      {showWhyZenadmin && <WhyZenAdmin />}
      <section className={classes.softwareIconsContainer}>
        <h3 className={clsx('h3-v1', classes.footerTitle)}>
          Seamlessly integrate your favorite tools with ease
        </h3>
        <div>
          <IconsMarquee
            // customStyles={{height: '100px'}}
            customClass={classes.marqueeContainer}
            animate={{x: ['0%', '-50%']}}
            list={repeatedSoftwareIcons}
            iconBoxClass={classes.swIconBox}
          />
          <IconsMarquee
            // customStyles={{height: '100px'}}
            animate={{x: ['-50%', '0%']}}
            list={repeatedSoftwareIcons}
            iconBoxClass={classes.swIconBox}
            customClass={classes.marqueeContainer}
          />
        </div>
        {/* <div className={classes.explorelinkButton}>Explore our integrations</div> */}
      </section>

      <footer className={classes.footer}>
        <div
          className={clsx(
            classes.footerImageCard,
            blueCardData?.paragraph ? classes.imageCardTop60 : classes.imageCardTop40,
          )}
        >
          <div className={classes.textContainer}>
            <div className={classes.footerCardTextContainer}>
              <p className={clsx(classes.footerCardText, 'h3-v1')}>
                <span>{blueCardData?.blueCardText1 || 'From hire to retire.'}</span>{' '}
                <span>{blueCardData?.blueCardText2 || 'One AI platform, zero manual work.'}</span>
              </p>
              {blueCardData?.paragraph ? (
                <p className="paragraph2-v1">{blueCardData?.paragraph}</p>
              ) : null}
            </div>
            <Link href={DEMO_LINK} target="_blank">
              <Button
                text={blueCardData?.buttonText || 'Book demo'}
                iconRight={
                  <span className={classes.btnIconBox}>
                    <Image src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
                  </span>
                }
                style={{background: '#fff', color: '#254dda', gap: '12px'}}
                className={classes.bookDemoBtn}
              />
            </Link>
          </div>
        </div>
        <div className={classes.footerContainer}>
          {/* <div className={classes.footerHeader}>
            <div className={classes.footerHeaderDetails}>
              <Image src={Logo} alt="" />
              <div className={classes.topLeftText}>{footerHeaderData?.leftText}</div>
            </div>
            <div>
              <p>{footerHeaderData?.rightText}</p>
              <div className={classes.inputContainer}>
                <input
                  placeholder="Enter your email"
                  className={classes.inputBox}
                  value={text}
                  onChange={e => {
                    setText(e.target.value)
                    setError(false)
                  }}
                />
                
              </div>
              <div className={classes.error}>{error}</div>
            </div>
          </div> */}
          {/* <ListSection
            data={footerProductsData}
            customFooterListContainer={classes.solutionFootListContainer}
            customListContainer={classes.productListContainer}
          /> */}
          <ListSection
            data={footerProductsData}
            customFooterListContainer={classes.solutionFootListContainer}
            customListContainer={classes.solutionListContainer}
          />
          <ListSection
            data={footerSolutionsData}
            customFooterListContainer={classes.solutionFootListContainer}
            customListContainer={classes.solutionListContainer}
          />
          <ListSection
            data={footerCompanyData}
            customFooterListContainer={classes.solutionFootListContainer}
            customListContainer={classes.companyListContainer}
          />
          <ListSection
            data={footerAddressData}
            customListContainer={classes.addressListContainer}
          />
          <div className={classes.footerBottomDetails}>
            <div className={classes.footerBottomLogo}>
              <Image
                src={ISOFooter}
                height={'38'}
                width={'47'}
                className={classes.isoImage}
                alt="iso-logo"
              />

              <p>{bottomFooterData?.copyrightText}</p>
            </div>
            <ul className={clsx(classes.flexCenter, classes.policyText)}>
              {bottomFooterData?.menu?.map((menu, idx) => (
                <Link key={idx} href={menu?.href}>
                  <li>{menu?.title}</li>
                </Link>
              ))}
            </ul>
            <div className={clsx(classes.flexCenter, classes.gap12)}>
              {/* {bottomFooterData?.socialMedia?.map((social, idx) => (
                <Link key={idx} href={social?.link} target="_blank">
                  <Image alt={social?.name} src={social?.icon} height={24} width={24} />
                </Link>
              ))} */}
              <Link href="https://x.com/zap_ai" target="_blank" aria-label="twitter">
                <FaXTwitter color="#fff" size={24} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/zenadmin-ai/"
                target="_blank"
                aria-label="linkedin"
              >
                <FaLinkedin color="#fff" size={24} />
              </Link>
            </div>
          </div>
        </div>
        <Image className={classes.lastFooterImage} src={FooterImage} alt="zenadmin-image" />
      </footer>
    </section>
  )
}
